@charset "UTF-8";
.img-fluid {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

/*===========================
reset style
===========================*/
/* autoprefixer grid: autoplace */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "游ゴシック体", "Yu Gothic", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  text-align: center;
  color: #24263f;
  background: #ffffff;
  font-size: 16px;
  letter-spacing: 1.2px;
  font-feature-settings: "palt";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: #24263f;
}

p {
  font-size: 16px;
  line-height: 1.5;
  color: #24263f;
}

ul,
ol,
li {
  list-style: none;
  color: #24263f;
}

a {
  text-decoration: none;
  color: #333333;
}
a:visited {
  text-decoration: none;
  color: #333333;
}
a:hover {
  text-decoration: none;
}

address {
  font-style: normal;
  color: #24263f;
}

img {
  border: none;
  vertical-align: bottom;
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
  color: #24263f;
}

main {
  display: block;
}

picture {
  display: block;
}

table,
th,
td {
  color: #24263f;
}

header {
  display: block;
}

footer {
  display: block;
}

aside {
  display: block;
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

small {
  display: block;
}

data {
  display: block;
}

time {
  display: block;
}

a[href^="tel:"] {
  pointer-events: none;
  cursor: default;
}
@media screen and (max-width: 960px) {
  a[href^="tel:"] {
    pointer-events: auto;
    cursor: pointer;
  }
}

/*==========================
l-header
==========================*/
.l-header {
  width: 100%;
  height: 110px;
  background: #ffffff;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9000;
  transform: translateY(0);
  transition: 0.3s;
}
.l-header.js-hide {
  transform: translateY(-100%);
  transition: 0.3s;
}
@media screen and (max-width: 960px) {
  .l-header {
    height: 70px;
    background: #ffffff;
  }
}
.l-header__inner {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 30px;
}
.l-header__split:nth-of-type(1) {
  width: 30%;
}
@media screen and (max-width: 960px) {
  .l-header__split:nth-of-type(1) {
    width: 62%;
  }
}
.l-header__split:nth-of-type(2) {
  width: 70%;
}
@media screen and (max-width: 960px) {
  .l-header__split:nth-of-type(2) {
    width: calc(100% - 62%);
  }
}
.l-header__logo {
  width: 250px;
}
@media screen and (max-width: 960px) {
  .l-header__logo {
    width: 100%;
  }
}
.l-header__logo a {
  display: block;
}
.l-header__logo img {
  width: 100%;
}
@media screen and (max-width: 960px) {
  .l-header__link {
    display: none;
  }
}
.l-header__linkDoboz:nth-of-type(1) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.l-header__linkSns {
  margin-right: 15px;
}
.l-header__linkSns a {
  transition: 0.3s;
}
.l-header__linkSns a:hover {
  opacity: 0.5;
}
.l-header__linkSearch {
  margin-right: 15px;
}
.l-header__linkContact .m-button--blue {
  width: 180px;
  height: 35px;
  transition: 0.3s;
}
.l-header__linkContact .m-button--blue a {
  font-size: 13px;
  transition: 0.3s;
}
@media screen and (max-width: 1200px) {
  .l-header__linkContact .m-button--blue a {
    font-size: 1.0833333333vw;
  }
}
.l-header__linkContact .m-button--blue a::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../img/global/header/linkContact.svg) no-repeat center;
  background-size: contain;
  margin-right: 6px;
}
.l-header__linkContact .m-button--blue a:hover {
  transition: 0.3s;
}
.l-header__linkContact .m-button--blue a:hover::before {
  background: url(../img/global/header/linkContact_on.svg) no-repeat center;
  background-size: contain;
  width: 20px;
  height: 20px;
}
.l-header__gnav {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  margin-top: 20px;
}
.l-header__gnav > li {
  padding-left: 30px;
}
.l-header__gnav > li:first-of-type {
  padding-left: 0;
}
.l-header__gnav a {
  display: block;
  font-size: 15px;
  color: #24263f;
  position: relative;
  transition: 0.3s;
}
@media screen and (max-width: 1200px) {
  .l-header__gnav a {
    font-size: 1.25vw;
  }
}
.l-header__gnav a:hover {
  color: #20519a;
}
.l-header__gnav a:hover::after {
  background-color: #ffffff;
}

#js-contents-wrapper {
  display: none;
}
@media screen and (max-width: 960px) {
  #js-contents-wrapper {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(0);
    transition: 0.3s;
    z-index: 9000;
  }
  #js-contents-wrapper.js-hide {
    transform: translateY(-100%);
    transition: 0.3s;
  }
}

/*==========================
l-footer
==========================*/
.l-footer {
  background-color: #3b4043;
  padding: 90px 0 40px;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 960px) {
  .l-footer {
    padding: 15.2% 0% 5.3333333333%;
  }
}
.l-footer__inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 99;
}
@media screen and (max-width: 960px) {
  .l-footer__inner {
    max-width: initial;
    padding: 0% 5.3333333333%;
  }
}
.l-footer__split:nth-of-type(1) {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
@media screen and (max-width: 960px) {
  .l-footer__split:nth-of-type(1) {
    display: block;
  }
}
.l-footer__split:nth-of-type(2) {
  text-align: left;
  margin-top: 90px;
}
@media screen and (max-width: 960px) {
  .l-footer__split:nth-of-type(2) {
    margin-top: 20px;
  }
}
.l-footer__main {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 80px;
}
@media screen and (max-width: 960px) {
  .l-footer__main {
    flex-wrap: wrap;
    gap: 30px;
  }
}
.l-footer__nav {
  width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
@media screen and (max-width: 960px) {
  .l-footer__nav {
    width: 100%;
  }
}
.l-footer__nav > li {
  width: 50%;
  text-align: left;
}
.l-footer__nav a {
  display: block;
  font-size: 12px;
  color: #ffffff;
  position: relative;
  padding: 4% 4%;
}
@media screen and (max-width: 960px) {
  .l-footer__nav a {
    padding: 4% 4%;
    font-size: 2.6666666667vw;
  }
}
.l-footer__nav a span {
  display: inline-block;
  padding-left: 10px;
  position: relative;
}
.l-footer__nav a span::after {
  content: "";
  display: block;
  width: calc(100% - 14px);
  height: 1px;
  position: absolute;
  top: auto;
  left: 15px;
  bottom: -5px;
  opacity: 0;
  transition: 0.3s;
  background-color: #20519a;
}
.l-footer__nav a::before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-top: 2px solid #20519a;
  border-right: 2px solid #20519a;
  transform: rotate(45deg);
  position: absolute;
  top: 40%;
  left: 0;
}
.l-footer__nav a:hover {
  color: #ffffff;
}
.l-footer__nav a:hover span::after {
  opacity: 1;
  transition: 0.3s;
}
.l-footer__sns {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
@media screen and (max-width: 960px) {
  .l-footer__sns {
    justify-content: flex-end;
    margin-top: 20px;
  }
}
.l-footer__sns li a {
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
}
.l-footer__sns li a:hover {
  opacity: 0.7;
  transition: 0.3s;
}
.l-footer__sns li + li {
  margin-left: 15px;
}
.l-footer__link {
  width: 300px;
}
@media screen and (max-width: 960px) {
  .l-footer__link {
    width: 100%;
  }
}
.l-footer__logo {
  background: #ffffff;
  padding: 20px;
}
@media screen and (max-width: 960px) {
  .l-footer__logo {
    width: 50vw;
  }
}
.l-footer__logo a {
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
}
.l-footer__logo a:hover {
  opacity: 0.7;
  transition: 0.3s;
}
.l-footer__logo img {
  max-width: 100%;
}
@media screen and (max-width: 960px) {
  .l-footer__logo img {
    max-width: 100%;
  }
}
.l-footer__address {
  margin-top: 20px;
}
.l-footer__address p {
  font-size: 12px;
  color: #ffffff;
  text-align: left;
  line-height: 1.75;
}
.l-footer__copy {
  margin-top: 46px;
  text-align: right;
}
@media screen and (max-width: 960px) {
  .l-footer__copy {
    margin-top: auto;
  }
}
.l-footer__copy p small {
  font-size: 12px;
  color: #ffffff;
}

/*============================
.l-container
============================*/
.l-container {
  /* IE11 */
}
_:-ms-lang(x)::-ms-backdrop, .l-container {
  overflow-x: hidden;
  overflow-y: visible;
}

/*============================
.l-section
============================*/
.l-section__inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 960px) {
  .l-section__inner {
    max-width: 100%;
  }
}

/*============================
.l-pankuzu
============================*/
.l-pankuzu {
  width: 100%;
}
.l-pankuzu__split {
  padding: 15px 0 15px 0;
  background: #f8f8f8;
}
@media screen and (max-width: 960px) {
  .l-pankuzu__split {
    padding: 15px 15px;
  }
}
.l-pankuzu__list {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 960px) {
  .l-pankuzu__list {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    overflow: auto;
    white-space: nowrap;
    padding-bottom: 10px;
    flex-wrap: nowrap;
  }
}
.l-pankuzu__list > li {
  font-size: 12px;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .l-pankuzu__list > li {
    font-size: 1vw;
  }
}
@media screen and (max-width: 960px) {
  .l-pankuzu__list > li {
    font-size: 3.2vw;
    display: inline;
  }
}
.l-pankuzu__list > li.is-full {
  width: 100%;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 0;
}
.l-pankuzu__list > li + li {
  margin-left: 10px;
}
.l-pankuzu__list > li a {
  color: #24263f;
}

/*============================
.l-main
============================*/
@media screen and (max-width: 960px) {
  .l-main-top {
    margin-top: 60px;
  }
}

/*============================
.slider
============================*/
.slick-slide:not(:first-child) {
  display: none;
}

/*============================
.l-section
============================*/
@keyframes clip-text {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
@keyframes text-revealer {
  0%, 50% {
    transform-origin: 0 50%;
  }
  60%, 100% {
    transform-origin: 100% 50%;
  }
  60% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
:root {
  --animation-delay: 0;
  --duration: 800ms;
  --iterations: 1;
}

.l-mv-top {
  position: relative;
}
.l-mv-top::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 80%;
  height: 80%;
  top: 0;
  left: 0;
  bottom: 0;
  background: #f7faff url(../img/top/mv/mv-bg.png);
  background-size: 50px;
}
@media screen and (max-width: 960px) {
  .l-mv-top::after {
    width: 90%;
  }
}
.l-mv-top__contents {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 50%;
  margin-left: -850px;
  text-align: left;
  z-index: 10;
}
@media screen and (min-width: 961px) and (max-width: 1750px) {
  .l-mv-top__contents {
    margin-left: 0;
    left: 20px;
    top: 10%;
    transform: none;
  }
}
@media screen and (max-width: 960px) {
  .l-mv-top__contents {
    top: -30px;
    left: 0;
    margin-left: 10px;
    transform: none;
  }
}
.l-mv-top__contentsTitle {
  animation-delay: 2.2s;
  animation-iteration-count: var(--iterations, 1);
  animation-duration: var(--duration, 800ms);
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.l-mv-top__contentsTitle::after {
  animation-delay: 2.2s;
  animation-iteration-count: var(--iterations, 1);
  animation-duration: var(--duration, 800ms);
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.l-mv-top__contentsTitle img {
  width: 100%;
}
.l-mv-top__contentsTitle--pc {
  display: block;
  width: 600px;
  padding: 20px;
}
@media screen and (max-width: 960px) {
  .l-mv-top__contentsTitle--pc {
    display: none;
  }
}
.l-mv-top__contentsTitle--sp {
  display: none;
}
@media screen and (max-width: 960px) {
  .l-mv-top__contentsTitle--sp {
    display: block;
    width: fit-content;
    padding: 10px 20px;
  }
  .l-mv-top__contentsTitle--sp + .l-mv-top__contentsTitle--sp {
    margin-top: 10px;
  }
}
.l-mv-top__contentsTitle {
  position: relative;
  animation-name: clip-text;
  background: rgba(255, 255, 255, 0.8);
}
.l-mv-top__contentsTitle::after {
  content: "";
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #20519a;
  transform: scaleX(0);
  transform-origin: 0 50%;
  pointer-events: none;
  animation-name: text-revealer;
}
.l-mv-top__contentsText {
  padding: 20px;
  font-size: 34px;
  color: #20519a;
  font-family: "Noto Serif JP", serif;
  margin-top: 20px;
  margin-left: 80px;
  width: fit-content;
  animation-delay: 2.5s;
  animation-iteration-count: var(--iterations, 1);
  animation-duration: var(--duration, 800ms);
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.l-mv-top__contentsText::after {
  animation-delay: 2.5s;
  animation-iteration-count: var(--iterations, 1);
  animation-duration: var(--duration, 800ms);
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.l-mv-top__contentsText--pc {
  display: block;
}
@media screen and (max-width: 960px) {
  .l-mv-top__contentsText--pc {
    display: none;
  }
}
.l-mv-top__contentsText--sp {
  display: none;
}
@media screen and (max-width: 960px) {
  .l-mv-top__contentsText--sp {
    display: block;
    width: fit-content;
    padding: 10px 20px;
    font-size: 20px;
    margin-left: 10px;
  }
  .l-mv-top__contentsText--sp + .l-mv-top__contentsText--sp {
    margin-top: 10px;
    margin-left: 130px;
  }
}
.l-mv-top__contentsText {
  position: relative;
  animation-name: clip-text;
  background: rgba(255, 255, 255, 0.8);
}
.l-mv-top__contentsText::after {
  content: "";
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #20519a;
  transform: scaleX(0);
  transform-origin: 0 50%;
  pointer-events: none;
  animation-name: text-revealer;
}
.l-mv-top__slider {
  padding-top: 50px;
}
@media screen and (min-width: 961px) and (max-width: 1750px) {
  .l-mv-top__slider {
    padding-top: 150px;
  }
}
.l-mv-top__sliderBox {
  width: calc((100vw - 1200px) / 2 + 915px);
  margin-left: auto;
}
@media screen and (max-width: 960px) {
  .l-mv-top__sliderBox {
    padding-top: 230px;
    width: calc(100% - 20px);
    padding-left: 20px;
  }
}
.l-mv-top__sliderBox img {
  width: 100%;
  height: auto;
}
.l-mv-page {
  background: #f7faff;
  padding: 50px 0;
}
@media screen and (max-width: 960px) {
  .l-mv-page {
    padding: 30px 20px;
  }
}
.l-mv-page__inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.l-mv-page__tit {
  text-align: left;
  position: relative;
}
.l-mv-page__tit::after {
  content: "";
  position: absolute;
  top: 35%;
  left: -70px;
  display: inline-block;
  width: 50px;
  height: 1px;
  background-color: #20519a;
}
@media screen and (max-width: 960px) {
  .l-mv-page__tit::after {
    display: none;
  }
}
.l-mv-page__tit span {
  display: block;
  font-size: 24px;
  margin-top: 10px;
  color: #20519a;
  letter-spacing: 0.1em;
  font-weight: 300;
}
@media screen and (max-width: 960px) {
  .l-mv-page__tit span {
    font-size: 18px;
  }
}

/*============================
.l-footcontact
============================*/
.l-footcontact {
  background: #20519a;
  padding: 60px 0;
}
@media screen and (max-width: 960px) {
  .l-footcontact {
    padding: 40px 0;
  }
}
.l-footcontact__main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
@media screen and (max-width: 960px) {
  .l-footcontact__main {
    gap: 30px;
    flex-wrap: wrap;
    width: 80vw;
    margin: auto;
  }
}
@media screen and (max-width: 960px) {
  .l-footcontact__mainItem {
    width: 100%;
  }
}
.l-footcontact .m-button--foot {
  width: 400px;
  height: auto;
  border: 1px solid #cdd6dd;
  border-radius: 5px;
  box-shadow: 0 5px 0 #e2e2e2;
  background: #ffffff;
  margin: 0 auto 0;
}
@media screen and (max-width: 960px) {
  .l-footcontact .m-button--foot {
    width: 100%;
  }
}
.l-footcontact .m-button--foot a {
  color: #24263f;
  border-radius: 5px;
  padding: 30px 0;
  display: block;
  transition: 0.3s;
  color: #20519a;
  font-size: 24px;
  font-weight: 500;
}
@media screen and (max-width: 960px) {
  .l-footcontact .m-button--foot a {
    padding: 10px 0 20px 0;
    font-size: 20px;
  }
}
.l-footcontact .m-button--foot a::before {
  content: "";
  display: block;
  margin: auto;
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}
@media screen and (max-width: 960px) {
  .l-footcontact .m-button--foot a::before {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .l-footcontact .m-button--foot a:hover {
    background: #20519a;
    border-radius: 5px;
    color: #ffffff;
  }
  .l-footcontact .m-button--foot a:hover::after {
    border-color: #ffffff;
  }
}
.l-footcontact .m-button--contact a::before {
  content: "";
  background: url(../img/global/footer/contact-icon.svg) no-repeat center;
  background-size: contain;
}
@media (hover: hover) and (pointer: fine) {
  .l-footcontact .m-button--contact a:hover::before {
    background: url(../img/global/footer/contact-icon_white.svg) no-repeat center;
    background-size: contain;
  }
}
.l-footcontact .m-button--showroom a::before {
  content: "";
  background: url(../img/global/footer/showroom-icon.svg) no-repeat center;
  background-size: contain;
}
@media (hover: hover) and (pointer: fine) {
  .l-footcontact .m-button--showroom a:hover::before {
    background: url(../img/global/footer/showroom-icon_white.svg) no-repeat center;
    background-size: contain;
  }
}

/*============================
.l-linklist
============================*/
/*============================
.l-information
============================*/
@media screen and (max-width: 960px) {
  .l-information__list {
    margin: 6.4% 0% 0%;
  }
}
.l-information__listItem {
  border-top: 1px dashed #cdd6dd;
  transition: 0.3s;
}
.l-information__listItem:hover {
  background-color: #f7faff;
  transition: 0.3s;
}
.l-information__listItem:last-of-type {
  border-bottom: 1px dashed #cdd6dd;
}
.l-information__list a {
  display: block;
  padding: 25px 20px;
  position: relative;
}
@media screen and (max-width: 960px) {
  .l-information__list a {
    padding: 15px 30px 15px 0;
  }
}
.l-information__list a::after {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  border-top: 1px solid #98a6b5;
  border-right: 1px solid #98a6b5;
  position: absolute;
  top: 50%;
  left: auto;
  right: 30px;
  transform: rotate(45deg) translateY(-50%);
}
@media screen and (max-width: 960px) {
  .l-information__list a::after {
    right: 10px;
  }
}
.l-information__listFlx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .l-information__listFlx {
    display: block;
  }
}
.l-information__listFlxSplit:nth-of-type(1) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .l-information__listFlxSplit:nth-of-type(1) {
    width: 100%;
  }
}
.l-information__listFlxSplit:nth-of-type(2) {
  margin-left: 30px;
}
@media screen and (max-width: 960px) {
  .l-information__listFlxSplit:nth-of-type(2) {
    margin-left: 0;
    width: 100%;
  }
}
.l-information__listDate {
  width: 90px;
  font-size: 13px;
  color: #24263f;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 960px) {
  .l-information__listDate {
    width: auto;
    font-size: 3.2vw;
    text-align: left;
  }
}
.l-information__listTit {
  font-size: 15px;
}
@media screen and (max-width: 960px) {
  .l-information__listTit {
    font-size: 14px;
  }
}

@media screen and (max-width: 960px) {
  .p-top-information {
    padding: 0% 5.3333333333%;
  }
}

/*============================
.l-box
============================*/
.l-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  margin: 80px auto;
}
@media screen and (max-width: 960px) {
  .l-box {
    display: block;
    margin: 30px auto 50px;
  }
}
.l-box__side {
  width: 235px;
  position: sticky;
  top: 50px;
  left: 0;
}
@media screen and (max-width: 960px) {
  .l-box__side {
    display: none;
  }
}
.l-box__main {
  width: 915px;
}
@media screen and (max-width: 960px) {
  .l-box__main {
    width: 100%;
  }
}
.l-box__main p {
  letter-spacing: 0.1em;
  line-height: 2.18;
}
.l-box__main p + p {
  margin-top: 20px;
}

/*============================
.l-sidebar
============================*/
.l-sidebar__searchTitle {
  margin-bottom: 10px;
  font-weight: 500;
}
.l-sidebar__category {
  border: 1px solid #cdd6dd;
  border-radius: 5px;
  margin-top: 30px;
}
.l-sidebar__categoryTitle {
  background: #f8f8f8;
  border-radius: 5px 5px 0 0;
  padding: 10px 15px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.l-sidebar__categoryIcon {
  width: 21px;
  margin-right: 5px;
}
.l-sidebar__categoryIcon img {
  margin-bottom: 2px;
}
.l-sidebar__categoryChild + .l-sidebar__categoryChild {
  margin-top: 25px;
}
.l-sidebar__categoryMain {
  padding: 20px 20px;
}
.l-sidebar__categoryMainTitle {
  margin-bottom: 15px;
  font-weight: 500;
}
.l-sidebar__categoryMainListItem a {
  border-bottom: dashed 1px #cdd6dd;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
  font-size: 14px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.l-sidebar__categoryMainListItem a:after {
  content: "";
  width: 5px;
  height: 5px;
  border-top: 1px solid #24263f;
  border-right: 1px solid #24263f;
  transform: rotate(45deg);
  transition: 0.3s;
}
.l-sidebar__categoryMainListItem a:hover {
  color: #20519a;
}
.l-sidebar__categoryMainListItem a:hover:after {
  content: "";
  border-top: 1px solid #20519a;
  border-right: 1px solid #20519a;
}
.l-sidebar__contact {
  margin-top: 30px;
}
.l-sidebar .m-button--sideContact {
  width: 100%;
  background-color: #20519a;
  border: 1px solid #20519a;
  border-radius: 5px;
  height: 50px;
  box-shadow: 0 5px 0 #e2e2e2;
  transition: 0.3s;
}
@media screen and (max-width: 960px) {
  .l-sidebar .m-button--sideContact {
    width: 100%;
  }
}
.l-sidebar .m-button--sideContact a {
  color: #ffffff;
  justify-content: flex-start;
  padding: 0 20px;
  transition: 0.3s;
}
.l-sidebar .m-button--sideContact:hover {
  transition: 0.3s;
  background-color: #ffffff;
  border-radius: 5px;
}
.l-sidebar .m-button--sideContact:hover a {
  color: #20519a;
  transition: 0.3s;
}
.l-sidebar__link {
  margin-top: 30px;
}
.l-sidebar__linkTitle {
  margin-bottom: 10px;
  font-weight: 500;
}
.l-sidebar .m-button--sideLink {
  width: 100%;
  border: 1px solid #20519a;
  border-radius: 5px;
  height: auto;
  box-shadow: 0 5px 0 #e2e2e2;
  transition: 0.3s;
}
@media screen and (max-width: 960px) {
  .l-sidebar .m-button--sideLink {
    width: 100%;
  }
}
.l-sidebar .m-button--sideLink a {
  color: #ffffff;
  justify-content: flex-start;
  padding: 20px;
}
.l-sidebar .m-button--sideLink:hover {
  transition: 0.3s;
  background-color: #f8f8f8;
  border-radius: 5px;
}
.l-sidebar .m-button--sideLink:hover a {
  color: #20519a;
}

/*============================
m-pagetop
============================*/
.m-pagetop {
  appearance: none;
  resize: none;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "游ゴシック体", "Yu Gothic", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  position: fixed;
  display: none;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  width: 60px;
  height: 60px;
  top: auto;
  bottom: 40px;
  left: auto;
  right: 20px;
  background: url(../img/global/footer/m-pagetop.svg) no-repeat left top;
  background-size: contain;
  z-index: 99999;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
}
.m-pagetop:hover {
  opacity: 0.7;
  transition: 0.3s;
}
@media screen and (max-width: 960px) {
  .m-pagetop {
    width: 60px;
    height: 60px;
  }
}

/*============================
m-button
============================*/
.m-button {
  width: 150px;
  height: 40px;
  background-color: #ffffff;
  position: relative;
  border-radius: 5px;
  margin: 0 auto;
}
@media screen and (max-width: 960px) {
  .m-button {
    width: calc(100% - 40px);
    margin: auto;
  }
}
.m-button a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #20519a;
  line-height: 1em;
  position: relative;
}
@media screen and (max-width: 960px) {
  .m-button a {
    font-size: 14px;
  }
}
.m-button--sideContact a {
  font-size: 13px;
  transition: 0.3s;
}
@media screen and (max-width: 1200px) {
  .m-button--sideContact a {
    font-size: 1.0833333333vw;
  }
}
.m-button--sideContact a::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../img/global/header/linkContact.svg) no-repeat center;
  background-size: contain;
  margin-right: 6px;
}
@media (hover: hover) and (pointer: fine) {
  .m-button--sideContact a:hover {
    transition: 0.3s;
  }
  .m-button--sideContact a:hover::before {
    background: url(../img/global/header/linkContact_on.svg) no-repeat center;
    background-size: contain;
    width: 20px;
    height: 20px;
  }
}
.m-button--arrowback {
  width: 400px;
  height: 60px;
  border: 1px solid #cdd6dd;
  border-radius: 5px;
  box-shadow: 0 5px 0 #e2e2e2;
  background: #ffffff;
  margin: 60px auto 0;
}
.m-button--arrowback a {
  color: #24263f;
  border-radius: 5px;
  transition: 0.3s;
}
.m-button--arrowback a::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-top: 1px solid #98a6b5;
  border-right: 1px solid #98a6b5;
  position: absolute;
  top: 50%;
  left: 30px;
  right: auto;
  transform: rotate(225deg) translateY(50%);
}
@media (hover: hover) and (pointer: fine) {
  .m-button--arrowback a:hover {
    background: #20519a;
    border-radius: 5px;
    color: #ffffff;
    transition: 0.3s;
  }
  .m-button--arrowback a:hover::after {
    border-color: #ffffff;
  }
}
.m-button--arrow {
  width: 400px;
  height: 60px;
  border: 1px solid #cdd6dd;
  border-radius: 5px;
  box-shadow: 0 5px 0 #e2e2e2;
  background: #ffffff;
  margin: 60px auto 0;
}
.m-button--arrow a {
  color: #24263f;
  border-radius: 5px;
  transition: 0.3s;
}
.m-button--arrow a::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-top: 1px solid #98a6b5;
  border-right: 1px solid #98a6b5;
  position: absolute;
  top: 50%;
  left: auto;
  right: 30px;
  transform: rotate(45deg) translateY(-50%);
}
@media (hover: hover) and (pointer: fine) {
  .m-button--arrow a:hover {
    background: #20519a;
    border-radius: 5px;
    color: #ffffff;
    transition: 0.3s;
  }
  .m-button--arrow a:hover::after {
    border-color: #ffffff;
  }
}
.m-button--blue {
  background-color: #20519a;
  border: 1px solid #20519a;
  border-radius: 5px;
  transition: 0.3s;
}
@media screen and (max-width: 960px) {
  .m-button--blue {
    width: 100%;
  }
}
.m-button--blue a {
  color: #ffffff;
}
@media (hover: hover) and (pointer: fine) {
  .m-button--blue:hover {
    transition: 0.3s;
    background-color: #ffffff;
  }
  .m-button--blue:hover a {
    color: #20519a;
  }
}

/*============================
m-hide
============================*/
@media screen and (max-width: 960px) {
  .m-hide--sp {
    display: none;
  }
}
.m-hide--pc {
  display: none;
}
@media screen and (max-width: 960px) {
  .m-hide--pc {
    display: block;
  }
}

/*============================
m-only
============================*/
.m-only--sp {
  display: none;
}
@media screen and (max-width: 960px) {
  .m-only--sp {
    display: block;
  }
}
.m-only--pc {
  display: block;
}
@media screen and (max-width: 960px) {
  .m-only--pc {
    display: none;
  }
}

/*============================
m-tit
============================*/
@keyframes clip-text {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
@keyframes text-revealer {
  0%, 50% {
    transform-origin: 0 50%;
  }
  60%, 100% {
    transform-origin: 100% 50%;
  }
  60% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.m-tit {
  margin-bottom: 20px;
  font-family: "Raleway", sans-serif;
  color: #20519a;
  font-style: italic;
  font-size: 36px;
  animation-delay: 0;
  animation-iteration-count: var(--iterations, 1);
  animation-duration: var(--duration, 800ms);
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  width: fit-content;
}
.m-tit::after {
  animation-delay: 0;
  animation-iteration-count: var(--iterations, 1);
  animation-duration: var(--duration, 800ms);
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
@media screen and (max-width: 960px) {
  .m-tit {
    font-size: 24px;
  }
}
@media screen and (max-width: 960px) {
  .m-tit {
    margin-bottom: 20px;
  }
}
.m-tit__jp {
  font-size: 18px;
  color: #24263f;
  font-weight: 500;
  display: block;
  font-style: normal;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 960px) {
  .m-tit__jp {
    font-size: 16px;
  }
}

[data-loading=true] .m-tit {
  position: relative;
}
[data-loading=true] .m-tit.is-active {
  animation-name: clip-text;
  background: rgba(255, 255, 255, 0.8);
}
[data-loading=true] .m-tit.is-active::after {
  content: "";
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #20519a;
  transform: scaleX(0);
  transform-origin: 0 50%;
  pointer-events: none;
  animation-name: text-revealer;
}

/*============================
m-noposts
============================*/
.m-noposts {
  width: 100%;
  padding: 0 20px;
  text-align: center;
}

/*============================
m-pagenavi
============================*/
.m-pagenavi {
  margin-top: 60px;
}
@media screen and (max-width: 960px) {
  .m-pagenavi {
    margin-top: 40px;
  }
}
.m-pagenavi .wp-pagenavi {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.m-pagenavi .pages {
  display: none;
}
.m-pagenavi a {
  font-size: 14px;
  display: inline-block;
  line-height: 1;
  color: #20519a !important;
  margin: 0 6px;
  transition: 0.3s;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  align-items: center;
  font-family: "Cardo", serif;
  letter-spacing: 0em;
  border-bottom: 1px solid #fff;
}
@media screen and (max-width: 960px) {
  .m-pagenavi a {
    font-size: 13px;
  }
}
.m-pagenavi a:hover {
  color: #20519a !important;
  border-bottom: 1px solid #20519a;
}
.m-pagenavi .current {
  color: #ffffff !important;
  background: #20519a;
  line-height: normal;
  font-size: 14px;
  width: 30px;
  height: 30px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: stretch;
  align-items: center;
  font-family: "Cardo", serif;
  letter-spacing: 0em;
}
.m-pagenavi .current:hover {
  color: #fff !important;
}

/*============================
m-desc
============================*/
.m-desc {
  text-align: left;
}
.m-desc--center {
  text-align: center;
}
.m-desc p {
  font-size: 16px;
  line-height: 1.87;
  letter-spacing: 0.12em;
}
@media screen and (max-width: 960px) {
  .m-desc p {
    font-size: 12px;
  }
}
@media screen and (max-width: 960px) {
  .m-desc p + p {
    margin-top: 20px;
  }
}
.m-desc--split p + p {
  margin-top: 30px;
}

/*============================
m-tit
============================*/
.m-subtit {
  font-size: 24px;
}
@media screen and (max-width: 960px) {
  .m-subtit {
    font-size: 16px;
  }
}
.m-subtit--bold {
  font-weight: bold;
}

/*============================
m-scroll
============================*/
.m-scroll {
  height: 100px;
}
.m-scroll__line {
  height: 100%;
}
.m-scroll__line::before {
  height: 100px;
  background-color: #ffffff;
  animation: scrollLine 1.4s linear infinite;
}
@keyframes scrollLine {
  0% {
    height: 100px;
  }
  100% {
    height: 0;
  }
}
@media screen and (max-width: 960px) {
  .m-scroll {
    height: 50px;
  }
  .m-scroll__line {
    height: 100%;
  }
  .m-scroll__line::before {
    height: 50px;
    background-color: #ffffff;
    animation: scrollLine 1.4s linear infinite;
  }
  @keyframes scrollLine {
    0% {
      height: 50px;
    }
    100% {
      height: 0;
    }
  }
}
.m-scroll__tit {
  font-size: 12px;
  color: #ffffff;
  padding-bottom: 10px;
}
.m-scroll__line {
  position: relative;
}
.m-scroll__line::before {
  content: "";
  width: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateZ(0);
}

/*============================
.m-select
============================*/
.m-select {
  width: 356px;
  height: 60px;
  margin: 0 auto;
  position: relative;
  border: 1px solid #cdd6dd;
}
@media screen and (max-width: 960px) {
  .m-select {
    width: 100%;
    height: 42px;
  }
}
.m-select::after {
  content: "";
  display: block;
  pointer-events: none;
  width: 8px;
  height: 8px;
  border-top: 1px solid #333333;
  border-right: 1px solid #333333;
  transform: rotate(135deg);
  position: absolute;
  top: 40%;
  left: auto;
  right: 20px;
}
@media screen and (max-width: 960px) {
  .m-select::after {
    width: 5px;
    height: 5px;
  }
}
.m-select select {
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "游ゴシック体", "Yu Gothic", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 20px;
  font-size: 16px;
}
.m-select select::-ms-expand {
  display: none;
}
@media screen and (max-width: 960px) {
  .m-select select {
    font-size: 12px;
    padding: 0 15px;
  }
}

/*============================
m-card
============================*/
.m-card__link {
  display: block;
}
.m-card__link:hover .m-card__tit {
  color: #20519a;
}
.m-card__img {
  position: relative;
  overflow: hidden;
}
.m-card__img::after {
  content: "";
  display: block;
  padding-top: 100%;
}
.m-card__img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: top;
  font-family: "object-fit:cover;";
}
.m-card__img img {
  transition: 0.3s;
}
.m-card__img:hover img {
  transition: 0.3s;
  transform: scale(1.1);
}
.m-card__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.m-card__data {
  font-size: 14px;
  color: #cdd6dd;
  font-family: "Cardo", serif;
  letter-spacing: 0em;
  letter-spacing: 0em;
}
.m-card__category {
  display: inline-block;
  background-color: #a59b42;
  font-size: 12px;
  color: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  padding: 3px 30px;
}
@media screen and (max-width: 960px) {
  .m-card__category {
    display: none;
  }
}
.m-card__tit {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  transition: 0.3s;
  margin-top: 5px;
}

/*============================
m-search
============================*/
.m-search__container {
  box-sizing: border-box;
  border: 1px solid #cdd6dd;
  padding: 10px;
  background: #f8f8f8;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.m-search__container input[type=text] {
  border: none;
  background: #f8f8f8;
  display: block;
}
.m-search__container input[type=text]::placeholder {
  color: #24263f;
  font-size: 10px;
  letter-spacing: 1px;
  font-family: "Noto Sans JP", sans-serif;
}
.m-search__container input:focus::-webkit-input-placeholder {
  color: transparent;
}
.m-search__container input:focus:-moz-placeholder {
  color: transparent;
}
.m-search__container input:focus::-moz-placeholder {
  color: transparent;
}
.m-search__container input[type=text]:focus {
  outline: 0;
}
.m-search__container button[type=submit] {
  cursor: pointer;
  border: none;
  background: none;
  color: #606060;
  outline: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 1em;
}
.m-search__container button[type=submit] img {
  vertical-align: middle;
}

/*==========================
共通タブ用 => .js-tab
==========================*/
.js-tab__switchItem {
  cursor: pointer;
}
.js-tab__main {
  position: relative;
}
.js-tab__mainItem {
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.js-tab__mainItem.has-scroll {
  display: none;
  position: static;
}
.js-tab__mainItem.is-active {
  opacity: 1;
  visibility: visible;
  position: static;
  z-index: auto;
}
.js-tab__mainItem.is-active.has-scroll {
  display: block;
}

/*===================
タブの中のタブ => .js-tab_inr
===================*/
.js-tabInr__switchItem {
  cursor: pointer;
}
.js-tabInr__main {
  position: relative;
}
.js-tabInr__mainItem {
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.js-tabInr__mainItem.has-scroll {
  display: none;
  position: static;
}
.js-tabInr__mainItem.is-active {
  opacity: 1;
  visibility: visible;
  position: static;
  z-index: auto;
}
.js-tabInr__mainItem.is-active.has-scroll {
  display: block;
}

/*==========================
共通トグル用 => .js-toggle
==========================*/
.js-toggle__switch {
  position: relative;
  cursor: pointer;
}
.js-toggle__switch::before, .js-toggle__switch::after {
  content: "";
  width: 13px;
  height: 2px;
  background: #000000;
  display: block;
  position: absolute;
  top: 50%;
  left: auto;
  right: 20px;
}
.js-toggle__switch::before {
  transform: translateY(-50%) rotate(0);
}
.js-toggle__switch::after {
  transition: 0.3s;
  transform: translateY(-50%) rotate(90deg);
}
.js-toggle__switch.is-active {
  transition: 0.3s;
}
.js-toggle__switch.is-active::after {
  transform: translateY(-50%) rotate(0deg);
}
.js-toggle__next {
  display: none;
}

/*==================
js-fadeIn
==================*/
.js-fadeIn {
  opacity: 0;
  transition: 1s;
}
.js-fadeIn.is-show {
  opacity: 1;
}
.js-fadeIn-up {
  transform: translate(0, 50px);
}
.js-fadeIn-up.is-show {
  transform: translate(0, 0);
  opacity: 1;
}

/*==========================
共通スライダー用 => .js-slider
==========================*/
.js-slider__modulDots {
  position: relative;
}
.js-slider__modulArw {
  cursor: pointer;
}

:root {
  --header-height: 60px;
}

.menu-button {
  appearance: none;
  background-color: #20519a;
  border: none;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  height: var(--header-height);
  padding: 0;
  position: relative;
  transition: background-color 0.3s;
  width: var(--header-height);
  display: flex;
  margin-left: auto;
}
.menu-button.focus-visible {
  background-color: #676f79;
}
@media (hover) {
  .menu-button:hover {
    background-color: #676f79;
  }
}

.menu-button__icon {
  bottom: 0;
  height: 2px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
}
.menu-button__icon::before, .menu-button__icon::after {
  background-color: #fff;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.menu-button__icon[data-type=open] {
  background-color: #fff;
}
.menu-button__icon[data-type=open]::before {
  transform: translateY(-6px);
}
.menu-button__icon[data-type=open]::after {
  transform: translateY(6px);
}
.menu-button__icon[data-type=close]::before {
  transform: rotate(45deg);
}
.menu-button__icon[data-type=close]::after {
  transform: rotate(-45deg);
}

.drawer-menu {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9050;
}

.drawer-menu__overlay {
  animation-duration: var(--menu-toggle-duration);
  animation-fill-mode: forwards;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.drawer-menu:not([inert]) .drawer-menu__overlay {
  animation-name: menu-overlay-appeared;
}
.drawer-menu[inert] .drawer-menu__overlay {
  animation-name: menu-overlay-leaved;
}

@keyframes menu-overlay-appeared {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes menu-overlay-leaved {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.drawer-menu__container {
  animation-duration: var(--menu-toggle-duration);
  animation-fill-mode: forwards;
  background-color: #fff;
  border-left: #e2e2e2 1px solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 440px;
  min-width: 280px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 80%;
}
.drawer-menu[inert] .drawer-menu__container {
  animation-name: menu-container-leaved;
}
.drawer-menu:not([inert]) .drawer-menu__container {
  animation-name: menu-container-appeared;
}

@keyframes menu-container-appeared {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes menu-container-leaved {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
.drawer-menu__list {
  flex: 1;
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0;
}

.drawer-menu__item {
  border-bottom: #e2e2e2 1px dashed;
}

.drawer-menu__link {
  text-align: left;
  align-items: center;
  color: inherit;
  display: block;
  justify-content: space-between;
  letter-spacing: 0.01em;
  padding: 1em 2.5em 1em 2em;
  position: relative;
  text-decoration: none;
  transition: background-color 0.3s;
}
.drawer-menu__link::after {
  border-right: 1px solid #aaa;
  border-top: 1px solid #aaa;
  bottom: 0;
  content: "";
  display: inline-block;
  height: max(8px, 0.5em);
  margin: auto 0;
  position: absolute;
  right: 24px;
  top: 0;
  transform: rotate(45deg);
  width: max(8px, 0.5em);
}
.drawer-menu__link:focus {
  background-color: rgba(0, 0, 0, 0.05);
}
.drawer-menu__link[aria-current] {
  background-color: rgba(0, 0, 0, 0.1);
}
.drawer-menu__link[aria-current]::after {
  content: none;
}
@media (hover) {
  .drawer-menu__link:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.drawer-menu__en-label {
  display: block;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-style: italic;
  text-transform: uppercase;
  font-size: max(18px, 0.75em);
}

.drawer-menu__jp-label {
  color: #aaa;
  display: block;
  font-size: max(10px, 0.75em);
  letter-spacing: 0.1em;
}

.drawer-menu__close-button {
  border-bottom: #e2e2e2 1px solid;
  order: -1;
  text-align: right;
}

.global-header {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.global-header__container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: 0;
}

.logo {
  padding-left: 15px;
}
.logo a {
  display: block;
  width: 150px;
}
.logo a img {
  display: block;
}

[inert] {
  cursor: default;
  pointer-events: none;
}

[inert],
[inert] * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.u-visually-hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/*==========================
共通POPUP用 => .js-popup
==========================*/
.popup {
  width: 100%;
  height: 100vh;
  padding: 0 24px;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  transition: 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup.popup-hidden {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.popup-inner {
  max-width: 500px;
  max-height: 500px;
  position: relative;
}
.popup-inner a {
  display: block;
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .popup-inner a:hover {
    opacity: 0.9;
  }
}
.popup-inner img {
  width: 100%;
}
.popup-inner #popup-btn {
  cursor: pointer;
  position: absolute;
  bottom: 100%;
  left: 100%;
  background: none;
  border: none;
}
@media screen and (max-width: 960px) {
  .popup-inner #popup-btn {
    left: auto;
    right: 0;
    top: -50px;
    bottom: auto;
  }
}
.popup-inner #popup-btn img {
  display: block;
  width: 40px;
}

/* slick-theme */
.slick-loading .slick-list {
  background: #fff url(../js/libs/slick/ajax-loader.gif) center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
  src: url("../js/libs/slick/fonts/slick.eot");
  src: url("../js/libs/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("../js/libs/slick/fonts/slick.woff") format("woff"), url("../js/libs/slick/fonts/slick.ttf") format("truetype"), url("../js/libs/slick/fonts/slick.svg#slick") format("svg");
}
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 999;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  opacity: 0.7;
}

.slick-prev {
  left: -25px;
  background-image: url("../js/libs/slick//img/prev_arrow.png");
}

.slick-next {
  right: -25px;
  background-image: url("../js/libs/slick//img/next_arrow.png");
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

/* slick */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*==========================
トップ
.p-top
==========================*/
body {
  overflow: hidden;
}

body[data-loading=true] {
  overflow: scroll;
}

/* Loading背景画面設定　*/
#splash {
  /*fixedで全面に固定*/
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: #fff;
  text-align: center;
  color: #fff;
}

/* Loading画像中央配置　*/
#splash_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Loading アイコンの大きさ設定　*/
#splash_logo img {
  width: 260px;
}

/* fadeUpをするアイコンの動き */
.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and (max-width: 960px) {
  .p-top-recommended {
    padding: 0% 0% 0% 5.3333333333%;
  }
}
.p-top-recommended__main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px 50px;
}
@media screen and (max-width: 960px) {
  .p-top-recommended__main {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
    gap: 30px;
  }
}
.p-top-recommended__mainItem {
  width: calc((100% - 50px * 1) / 2);
  position: relative;
}
@media screen and (max-width: 960px) {
  .p-top-recommended__mainItem {
    scroll-snap-align: start;
    white-space: normal;
    width: auto;
  }
}
@media screen and (max-width: 960px) {
  .p-top-recommended__mainItem a {
    display: block;
    width: 75vw;
  }
}
@media (hover: hover) and (pointer: fine) {
  .p-top-recommended__mainItem a:hover .p-top-recommended__image::before {
    transform-origin: left top;
    transform: skewY(-10deg) scale(1, 1);
  }
  .p-top-recommended__mainItem a:hover .p-top-recommended__image::after {
    opacity: 1;
  }
  .p-top-recommended__mainItem a:hover p {
    color: #20519a;
  }
}
.p-top-recommended__image {
  display: block;
  position: relative;
  overflow: hidden;
}
.p-top-recommended__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.p-top-recommended__image::before {
  content: "";
  position: absolute;
  top: 0;
  left: -5%;
  width: 110%;
  height: 140%;
  background-color: #20519a;
  transform-origin: left bottom;
  transform: skewY(-12deg) scale(1, 0);
  transition: transform 0.4s;
  opacity: 0.5;
}
.p-top-recommended__image::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-image: url(../img/global/arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
  background-color: #ffffff;
  border-radius: 50vh;
  border: 2px solid #20519a;
  opacity: 0;
  transition: opacity 0.3s;
}
.p-top-recommended__text p {
  line-height: 2;
  font-size: 16px;
  transition: 0.3s;
}
.p-top-recommended__text p:first-of-type {
  margin: 10px 0;
}
@media screen and (max-width: 960px) {
  .p-top-recommended .m-button--sp {
    padding-right: 20px;
  }
}
.p-top-information {
  margin-top: 80px;
}
@media screen and (max-width: 960px) {
  .p-top-information {
    margin-top: 50px;
    padding: 0% 5.3333333333%;
  }
}
.p-top-showroom {
  margin-top: 80px;
}
@media screen and (max-width: 960px) {
  .p-top-showroom {
    margin-top: 50px;
    padding: 0% 5.3333333333%;
  }
}
.p-top-showroom__map iframe {
  width: 100%;
  height: 300px;
}
.p-top-showroom__main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  margin-top: 30px;
}
@media screen and (max-width: 960px) {
  .p-top-showroom__main {
    display: block;
  }
}
.p-top-showroom__mainItem {
  width: calc((100% - 50px * 1) / 2);
}
@media screen and (max-width: 960px) {
  .p-top-showroom__mainItem {
    width: 100%;
  }
}
.p-top-showroom__text p {
  border-bottom: dashed 1px #cdd6dd;
  margin-bottom: 20px;
  padding-bottom: 20px;
  line-height: 2;
}
.p-top-showroom__text p:first-of-type {
  border-top: dashed 1px #cdd6dd;
  padding-top: 20px;
  font-size: 18px;
  font-weight: 500;
}
.p-top-showroom__text p:last-child {
  margin-bottom: 0;
}
.p-top-special {
  margin-top: 80px;
}
@media screen and (max-width: 960px) {
  .p-top-special {
    margin-top: 50px;
    padding: 0% 5.3333333333%;
  }
}
.p-top-special__img a {
  transition: 0.3s;
  display: block;
}
@media (hover: hover) and (pointer: fine) {
  .p-top-special__img a:hover {
    opacity: 0.7;
  }
}
